import React, { useEffect, useState } from 'react';
import { GlobalError } from '@dnb/eufemia';
import MainLayout from 'components/layout/MainLayout';
import { PageProps } from 'gatsby';
import { CXM_PAGE_LOADING_STATE } from 'utils/constants';


const AccessDenied: React.FC<PageProps> = () => {
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);
  if (!isMount) {
    return <div>{CXM_PAGE_LOADING_STATE}</div>;
  }
  return (
    <MainLayout
      theme={{
        backgroundColor: 'inherit',
        color: 'inherit',
        logoColor: 'inherit',
        linkColor: 'inherit',
      }}
    >
      <GlobalError status="403" title="Access Denied"/>
    </MainLayout>
  );
};

export default AccessDenied;